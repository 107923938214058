import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Login from '../components/Login'
import Layout from '../components/layout';
import { State, useAppDispatch } from '../store';
import { useAuthenticationStatus } from '../components/hooks';
import { navigate } from 'gatsby';
import { fetchSelf } from '../store/User/actions';
import LoadingSmall from '../components/Loading/LoadingSmall';

const Index = () => {
  const dispatch = useAppDispatch();
  const [isAuthenticated, loading] = useAuthenticationStatus();
  const user = useSelector((state: State) => state.users.self);

  useEffect(() => {
  if (loading) {
    return;
  }
  if (isAuthenticated && !user) {
    dispatch(fetchSelf());
  } else if (user?.is_superuser) {
    navigate('/communications/');
  } else if (typeof window !== 'undefined') {
    // window.location.href = 
    //   process.env.NODE_ENV === 'development'
    //     ? `http://localhost:3001/`
    //     : 'https://login.listwithclever.com';
  }
}, [isAuthenticated, loading, user]);

  return (
    <Layout>
      <LoadingSmall />
    </Layout>
  )
};

export default Index;