/* eslint-disable max-depth */
import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useSelector } from 'react-redux';
import { selectMagicLinksSuccess } from '../../../store/MagicLinks/selectors';
import { useAppDispatch } from '../../../store';
import { fetchSelf } from '../../../store/User/actions';
import { storeTokensInLocalStorage } from '../../../config/Cognito/helpers/storage';

export default function useAuthenticationStatus(): [boolean, boolean] {
  // Set default to true until we can check if they are authenticated
  const dispatch = useAppDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  const magicLinkSuccess = useSelector((state) =>
    selectMagicLinksSuccess(state),
  ) as boolean;

  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          await storeTokensInLocalStorage();
          const accessTokenExpire = session.getAccessToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          // if token has expired refresh it and set authed to true
          if (accessTokenExpire < currentTimeSeconds) {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            if (authenticatedUser) {
              await authenticatedUser.refreshSession(refreshToken);
              await storeTokensInLocalStorage();
              dispatch(fetchSelf());
              setIsAuthenticated(true);
              setLoading(false);
              return;
            }
            // if this occurs then something weird has happened and then lets just set them to not authenticated
            setIsAuthenticated(false);
            setLoading(false);
            return;
          }
          // tokens are not expired
          dispatch(fetchSelf());
          setIsAuthenticated(true);
          setLoading(false);
          return;
        }
        setIsAuthenticated(false);
        setLoading(false);
        return;
      } catch (e) {
        // if at any point this fails then we are not authenticated
        setIsAuthenticated(false);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // If Magic link is success means auth went through
    if (magicLinkSuccess) {
      setIsAuthenticated(true);
      setLoading(false);
    }
  }, [magicLinkSuccess]);

  return [isAuthenticated, loading];
}
