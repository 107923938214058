import { Auth } from "@aws-amplify/auth"

const storeTokensInLocalStorage = async () => {
  try {
    const session = await Auth.currentSession() // gets the current session
    const idToken = session.getIdToken().getJwtToken()
    const accessToken = session.getAccessToken().getJwtToken()
    const refreshToken = session.getRefreshToken().getToken()

    localStorage.setItem("idToken", idToken)
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("refreshToken", refreshToken)
    return true
  } catch (error) {
    console.error("Error storing tokens in local storage", error)
    return false
  }
}

const grabTokensFromLocalStorage = () => {
  const idToken = localStorage.getItem("idToken")
  const accessToken = localStorage.getItem("accessToken")
  const refreshToken = localStorage.getItem("refreshToken")
  return { idToken, accessToken, refreshToken }
}

export { storeTokensInLocalStorage, grabTokensFromLocalStorage }
